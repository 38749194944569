import React from "react";
import Background2 from "../../images/image-woman.png";
import IconCircle from "../../images/icon-circle-dark-blue.png";
import { Parallax } from "react-scroll-parallax";

const SecondSection = () => (
  <>
    <div className="parallax bg-center bg-no-repeat">
      <Parallax speed={-10} translateY={["-300px", "300px"]}>
        <img src={IconCircle} className="absolute" />
      </Parallax>
      <div className="container items-center relative z-10">
        <div className="grid gap-y-28 sm:gap-y-48 lg:gap-y-80 relative col-span-12">
          <div className="col-span-12 py-64 pb-40 flex items-center">
            <h2 className="text-white text-4xl lg:text-5xl xl:text-7xl font-extralight inline-block">
              <strong className="font-bold">SKROL</strong> is on a mission to{" "}
              <strong className="text-gradient-secondary font-regular">
                modernize
              </strong>{" "}
              the backbone of the global economy by{" "}
              <span className="text-gradient-secondary font-regular">
                transforming
              </span>{" "}
              <strong className="font-semibold">small and medium sized</strong>{" "}
              <span className="text-gradient-secondary">businesses</span>{" "}
              globally.
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div className="container mb-28 relative">
      <div className="grid grid-cols-12 col-span-12 items-center gap-10">
        <div className="col-span-10 md:col-span-6">
          <h2 className="text-white text-4xl sm:text-[50px] lg:text-[70px] xl:text-[80px] leading-tight font-regular text-left">
            <strong>SMBs</strong> aren't <br />
            just <span className="text-secondary">businesses</span>
          </h2>
        </div>
        <div
          className="col-span-10 md:col-span-6 h-[350px] xl:h-[500px] bg-cover rounded-2xl"
          style={{
            backgroundImage: `url(${Background2})`,
          }}
        ></div>
      </div>
    </div>
  </>
);

export default SecondSection;
