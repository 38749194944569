import React from "react";
import { Link } from "gatsby";
import { onSendToGa } from "../utils/events-click";
import { useSellBusiness } from "../contexts/sellBusinessContext";
import Logo from "./icons/Logo";
import LogoSKROLAI from "../images/products/skrol-ai-small.svg";

const Header = () => {
  const { handleOpen } = useSellBusiness();

  return (
    <>
      <header className="bg-black py-6 fixed z-30 w-full">
        <div className="container items-center">
          <div className="col-span-3 sm:col-span-4">
            <Link to="/" className="w-full sm:w-[130px] block">
              <Logo fill="white" />
            </Link>
          </div>
          <nav className="flex justify-end sm:col-start-7 sm:col-span-6 col-span-9">
            <ul className="flex gap-3 sm:gap-6">
              <li className="text-white text-md sm:text-lg">
                <Link to="/about-us">About</Link>
              </li>
              <li className="text-white text-md sm:text-lg">
                <a
                  onClick={() => onSendToGa("Careers header link")}
                  target="_blank"
                  href="https://forms.gle/h6hE8G1j74t437e29"
                >
                  Careers
                </a>
              </li>
              <li className="text-white text-md sm:text-lg">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    onSendToGa("SKROL AI");
                    document
                      .querySelector("#our-products")
                      ?.scrollIntoView({ behavior: "smooth" });
                  }}
                  href="#our-products"
                >
                  Our products
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
