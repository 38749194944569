import React, { useMemo } from "react";
import Slider from "react-slick";
import LogoSign from "../../images/products/skrol-sign-white.svg";
import LogoInvoiceGuard from "../../images/products/skrol-invoice-guard-white.svg";
import LogoSearch from "../../images/products/skrol-search-white.svg";
import LogoSKROLAI from "../../images/products/skrol-ai-white.svg";

const OurProducts = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
  };

  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="parallax bg-center bg-no-repeat py-20 sm:py-32"
      id="our-products"
    >
      <div className="container h-full flex-col relative z-10">
        <div className="flex flex-col gap-10 sm:gap-16 col-span-12 justify-center h-full">
          <div className="grid grid-cols-10 col-span-10 gap-5">
            <div className="col-span-10 sm:col-span-8 lg:col-span-6 flex flex-col gap-4">
              <h2 className="text-white text-3xl sm:text-5xl lg:text-6xl font-semibold">
                Our Products
              </h2>
              <p className="text-xl">
                SKROL is building software and AI assistants for small and
                medium-sized businesses. Below is our first suite of SMB focused
                products for unloved but critical SMB workflows.
              </p>
            </div>
          </div>
          <Slider {...settings}>
            <div>
              <a href="https://flowsign.com/" target="_blank">
                <img className="h-10 mx-auto" src={LogoSign} />
              </a>
            </div>
            <div>
              <img className="h-9 mx-auto" src={LogoInvoiceGuard} />
              <p className="text-center text-white/50">(Coming soon)</p>
            </div>
            <div>
              <a href="https://skrol.ai/" target="_blank">
                <img className="h-9 mx-auto" src={LogoSKROLAI} />
              </a>
            </div>
            <div>
              <a href="https://search.skrol.com/" target="_blank">
                <img className="h-9 mx-auto" src={LogoSearch} />
              </a>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default OurProducts;
