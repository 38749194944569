import React from "react";
import Background from "../../images/image-contact.png";
import { useSellBusiness } from "../../contexts/sellBusinessContext";
import { Link } from "gatsby";
import Logo from "../icons/Logo";

const ContactSection = () => {
  const { handleOpen } = useSellBusiness();

  return (
    <div
      className="bg-cover relative py-32 flex items-center"
      style={{
        backgroundImage: `url(${Background})`,
      }}
    >
      <div className="absolute bg-gradient-to-b from-black to-black/40 inset-0"></div>
      <div className="container py-10 sm:py-20 relative">
        <div className="flex flex-col gap-4 sm:gap-8 col-span-12 sm:col-span-12 lg:col-span-12 relative text-center">
          <Link to="/" className="w-[120px] sm:w-[180px] block mx-auto">
            <Logo fill="white" />
          </Link>
          <h2 className="text-white text-4xl xl:text-5xl font-semibold">
            Contact us
          </h2>
          <p className="text-white text-md sm:text-xl font-thin">
            <strong className="font-bold">SKROL Capital, Inc.</strong>
            <br />
            801 Brickell Avenue, 8th Fl.
            <br />
            Miami, FL 33131
            <br />
            <a href="mailto:inbound@skrol.com">inbound@skrol.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
