import React from "react";
import Layout from "./src/components/Layout";

export const onPreRenderHTML = ({
  getHeadComponents,
  replaceHeadComponents,
}) => {
  const headComponents = getHeadComponents();

  const loaderStyle = (
    <style key="loader-style">
      {`
        .loader {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9999;
        }
        .loader.hidden {
          display: none;
        }
      `}
    </style>
  );

  headComponents.push(loaderStyle);
  replaceHeadComponents(headComponents);
};

export const onRenderBody = ({ setPreBodyComponents }) => {
  setPreBodyComponents([
    <div key="loader" id="loader" className="loader">
      <div className="flex flex-col gap-4">
        <div className="relative z-20 h-[64px] overflow-hidden flex items-center">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 128 32"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.3624 31.9992C10.4583 31.9992 8.72515 31.7242 7.16277 31.1734C5.6004 30.6234 4.21717 29.8277 3.01226 28.7861C1.80736 27.7453 0.803271 26.4804 0 24.9922L6.06953 21.02C6.8428 22.1216 7.84689 23.0065 9.08179 23.6756C10.3159 24.3448 11.5733 24.6797 12.8532 24.6797C13.8356 24.6797 14.5714 24.4873 15.0622 24.0998C15.553 23.714 15.7988 23.2374 15.7988 22.6724C15.7988 22.2858 15.6871 21.9433 15.4638 21.6458C15.2405 21.3483 14.8013 21.0584 14.1472 20.775C13.4923 20.4926 12.5407 20.2168 11.2908 19.9493C8.67182 19.3543 6.58949 18.6035 5.04294 17.6953C3.49556 16.7879 2.39398 15.6871 1.7407 14.393C1.08575 13.099 0.759107 11.6191 0.759107 9.95256C0.759107 8.1077 1.2349 6.4345 2.18733 4.93211C3.13892 3.42973 4.50048 2.23149 6.27118 1.33906C8.04104 0.446632 10.1459 0 12.5865 0C15.3238 0 17.6595 0.543292 19.5935 1.62904C21.5275 2.71562 23.0149 4.10635 24.0565 5.80121L17.9869 10.175C17.302 9.25261 16.3871 8.59099 15.2421 8.18936C14.0964 7.78772 13.0031 7.58691 11.9616 7.58691C11.0391 7.58691 10.3025 7.75856 9.75257 8.1002C9.20178 8.44267 8.9268 8.91097 8.9268 9.50592C8.9268 10.1009 9.23178 10.5775 9.84173 10.9341C10.4508 11.2916 11.6641 11.6783 13.4789 12.0941C15.9779 12.6598 18.0236 13.3815 19.616 14.2589C21.2075 15.1371 22.3833 16.2229 23.1415 17.5178C23.9006 18.8119 24.2798 20.3959 24.2798 22.2708C24.2798 24.1456 23.804 25.8188 22.8515 27.2912C21.8991 28.7644 20.5309 29.9177 18.746 30.7501C16.9612 31.5825 14.833 32 12.364 32L12.3624 31.9992Z" />
            <path d="M51.8151 0.803345H41.8184L32.9808 11.8066V0.803345H24.9031V31.1968H32.9808V20.3943L41.2826 31.1968H51.6368L39.5944 16.0047L51.8151 0.803345Z" />
            <path d="M66.5881 31.1968H75.4249L70.4503 21.0984C71.666 20.3235 72.6768 19.3236 73.4834 18.0978C74.5691 16.4463 75.1124 14.4006 75.1124 11.9608C75.1124 9.521 74.5691 7.47532 73.4834 5.82379C72.3968 4.17225 70.9394 2.92318 69.1104 2.07491C67.2805 1.22748 65.2498 0.803345 63.0184 0.803345H51.7717V31.1968H59.8494V23.0741H62.5875L66.5889 31.1968H66.5881ZM59.8486 8.65856H62.75C64.1183 8.65856 65.1523 8.94104 65.8523 9.506C66.5506 10.0718 66.9005 10.8901 66.9005 11.9608C66.9005 13.0316 66.5506 13.8798 65.8523 14.4148C65.1523 14.9506 64.1183 15.218 62.75 15.218H59.8486V8.65773V8.65856Z" />
            <path d="M127.573 31.1968H108.382V0.803345H116.461V23.3399L127.573 23.3407V31.1968Z" />
            <path
              d="M92.195 31.39C89.1759 31.39 86.5143 30.7569 84.2102 29.4906C81.9458 28.1848 80.178 26.4041 78.9068 24.1486C77.6356 21.8536 77 19.2023 77 16.195C77 13.1877 77.6356 10.5562 78.9068 8.30072C80.178 6.00564 81.9458 4.22498 84.2102 2.95873C86.5143 1.65291 89.1759 1 92.195 1C95.2141 1 97.8559 1.65291 100.12 2.95873C102.424 4.22498 104.212 6.00564 105.483 8.30072C106.754 10.5562 107.39 13.1877 107.39 16.195C107.39 19.2023 106.754 21.8536 105.483 24.1486C104.212 26.4041 102.424 28.1848 100.12 29.4906C97.8559 30.7569 95.2141 31.39 92.195 31.39ZM92.195 23.8519C94.3402 23.8519 96.0881 23.1396 97.4388 21.7151C98.7894 20.2905 99.4648 18.4505 99.4648 16.195C99.4648 13.8999 98.7894 12.0401 97.4388 10.6156C96.0881 9.19105 94.3402 8.47879 92.195 8.47879C90.0498 8.47879 88.3019 9.19105 86.9512 10.6156C85.6403 12.0005 84.9848 13.8406 84.9848 16.1356C84.9848 18.4307 85.6403 20.2905 86.9512 21.7151C88.3019 23.1396 90.0498 23.8519 92.195 23.8519Z"
              className="animate-move"
            />
          </svg>
        </div>
      </div>
    </div>,
    <script
      key="loader-removal"
      dangerouslySetInnerHTML={{
        __html: `
          document.addEventListener('DOMContentLoaded', function() {
            var loader = document.getElementById('loader');
            if (loader) {
            setTimeout(() => {
              loader.classList.add('invisible');
              loader.classList.add('opacity-0');
            }, "300");
            }
          });
        `,
      }}
    />,
  ]);
};

export const wrapPageElement = ({ element, props }) => {
  const isSearch = !props.location.pathname.includes("/search");

  return <>{isSearch ? <Layout {...props}>{element}</Layout> : element}</>;
};
